import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { FaRegFileAlt } from 'react-icons/fa'
import { FaRegFileLines } from 'react-icons/fa6'
import { toast } from 'react-toastify'
import FButton from '../../../components/FButton'
import FRow from '../../../components/FRow'
import { useSearchParams } from 'react-router-dom'
import { axiosInstance } from '../../../api/requister'
import moment from 'moment'

const ListTasks = () => {
  const reader = {
    CUSTOMER_TRANSACTION_ORACLE_FILE: 'ملف الدفع',
    UPLOAD_GIG_RESPONSE: 'ملف رد التامين',
    UPLOAD_CUSTOMER: 'ملف العملاء',
  }

  const [searchParams, setSearchParams] = useSearchParams()

  // Get current page from the URL, default to 0 if not found
  const currentPage = parseInt(searchParams.get('page')) || 0
  const pageSize = parseInt(searchParams.get('size')) || 25

  const [selectedTasks, setSelectedTasks] = useState([])

  const fetchTasks = async () => {
    const response = await axiosInstance(
      '/FailedRowsUploadReport/listAllFailedRows',
      {
        method: 'GET',
        params: {
          page: currentPage,
          size: pageSize,
          status: 'OPEN',
        },
      },
    )
    return response.data
  }

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['tasks', currentPage, pageSize], // include pagination parameters in queryKey
    queryFn: fetchTasks,
  })

  const totalCount = data?.totalCount

  const handlePageChange = newPage => {
    setSearchParams({ page: newPage, size: pageSize })
  }

  if (isLoading) {
    return <>loading ...</>
  }

  return (
    <>
      <h1 className={'text-lg m-3'}>جميع المهام</h1>
      <table className='min-w-full table-auto bg-white shadow-md rounded border-collapse'>
        <thead>
          <tr className='bg-gray-100 text-right'>
            <th className='px-4 py-2 '>الوصف</th>
            <th className='px-4 py-2 '>تاريخ الملف</th>
            <th className='px-4 py-2 '>المستخدم</th>
            <th className='px-4 py-2 '>كود الملف</th>
            <th className='px-4 py-2 '>الرد</th>
            <th className='px-4 py-2 '>الملف</th>
            <th className='px-4 py-2 '>النوع</th>
            <th className='px-4 py-2 '>الحالة</th>
          </tr>
        </thead>
        <tbody>
          {data?.results?.map(task => (
            <tr
              key={task.id}
              className={`border-t ${
                selectedTasks.includes(task.id)
                  ? 'bg-gray-100 border-gray-300'
                  : 'bg-white border-gray-100'
              }`}
            >
              <td className='px-4 py-2'>
                <FRow>
                  <input
                    type='checkbox'
                    onChange={() => {
                      setSelectedTasks(prevSelected =>
                        prevSelected.includes(task.id)
                          ? prevSelected.filter(id => id !== task.id)
                          : [...prevSelected, task.id],
                      )
                    }}
                    className='mx-2'
                  />
                  <span className='text-sm whitespace-break-spaces'>
                    {task.description}
                  </span>
                </FRow>
              </td>

              <td className='px-4 py-2'>
                {moment(task.exportedAt).format('YYYY-MM-DD')}
              </td>
              <td className='px-4 py-2'>{task.exporteBy.email}</td>

              <td className='px-4 py-2'>{task.exportFileHistoryId}</td>
              <td className='px-4 py-2'>
                <a
                  className='flex gap-1 items-center underline'
                  href={task.responseUri}
                >
                  <FaRegFileAlt />
                  Response
                </a>
              </td>
              <td className='px-4 py-2'>
                <a
                  className='flex gap-1 items-center underline'
                  href={task.uri}
                >
                  <FaRegFileLines />
                  File
                </a>
              </td>
              <td className='px-4 py-2'>
                {reader[task.exportExcelType] || task.exportExcelType}
                <small className='block'>صف رقم {task.rowNumberInSheet}</small>
              </td>
              <td className='px-4 py-2'>
                <select
                  value={task.status}
                  onChange={async e => {
                    await axiosInstance.post(
                      `FailedRowsUploadReport/updateFailedRow`,
                      {
                        status: e.target.value,
                        rowId: task.id,
                      },
                    )
                    toast.success('Task updated successfully')
                    await refetch()
                  }}
                  className='border border-gray-300 p-1 rounded'
                >
                  <option value='OPEN'>Open</option>
                  <option value='RESOLVED'>RESOLVED</option>
                  <option value='NOT_AN_ISSUE'>NOT_AN_ISSUE</option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <FRow
        className={
          'justify-between p-3 bg-gray-100 border-t sticky z-20 bottom-0 flex w-full items-center'
        }
      >
        <FRow>
          <FButton
            onClick={() => handlePageChange(Math.max(currentPage - 1, 0))}
            disabled={currentPage === 0}
          >
            السابق
          </FButton>
          <FButton
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage >= Math.ceil(totalCount / pageSize) - 1}
          >
            التالي
          </FButton>
        </FRow>
        {`عرض ${pageSize} من ${totalCount}`}
      </FRow>
    </>
  )
}

export default ListTasks
