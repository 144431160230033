import { useFormik } from 'formik'
import FButton from '../../components/FButton'
import FInputField from '../../components/FInputField'
import FLabel from '../../components/FLabel'
import EFormWrapper from '../../components/EFormWrapper'
import * as yup from 'yup'
import EFormInvalidInput from '../../components/EFormInvalidInput'
const PasswordReset = () => {
  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit: values => {
      console.log(values)
    },
    validationSchema: yup.object().shape({
      newPassword: yup.string().required('كلمة المرور مطلوبة'),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('newPassword'), null], 'كلمة المرور غير متطابقة'),
    }),
  })

  return (
    <div className={'bg-orient-500 min-h-screen'}>
      <form
        onSubmit={formik.handleSubmit}
        className={
          'mx-auto bg-white w-5/12   border rounded-lg  p-3 shadow border-gray-800'
        }
      >
        <h1 className={'font-medium'}>تغيير كلمة المرور</h1>
        <small>
          يجب أن تحتوي كلمة المرور على 8 أحرف على الأقل وتحتوي على حرف كبير و
          حرف صغير ورقم ورمز
        </small>
        <EFormWrapper>
          <FLabel htmlFor='newPassword'>كلمة المرور الجديدة</FLabel>
          <FInputField
            id='newPassword'
            name='newPassword'
            type='password'
            placeholder='كلمة المرور الجديدة'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.newPassword}
          />
          <EFormInvalidInput
            FieldName={'newPassword'}
            errors={formik.errors}
            touched={formik.errors}
            formik={formik}
          />
        </EFormWrapper>
        <EFormWrapper>
          <FLabel htmlFor='confirmPassword'>تأكيد كلمة المرور</FLabel>
          <FInputField
            id='confirmPassword'
            name='confirmPassword'
            type='password'
            placeholder={'تأكيد كلمة المرور'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
          />
          <EFormInvalidInput
            FieldName={'confirmPassword'}
            errors={formik.errors}
            touched={formik.errors}
            formik={formik}
          />
        </EFormWrapper>
        <FButton type='submit'>تأكيد</FButton>
      </form>
    </div>
  )
}
export default PasswordReset
