import * as Yup from 'yup'
import { Formik } from 'formik'
import SFormWrapper from '../../../components/EFormWrapper'
import FLabel from '../../../components/FLabel'
import FInputField from '../../../components/FInputField'
import FButton from '../../../components/FButton'
import { useState } from 'react'
import { useCustomAxios } from '../../../Hooks/useAxios'
import { axiosInstance } from '../../../api/requister'
import { toast } from 'react-toastify'
import ESpinner from '../../../components/ESpinner'
import FIconWrapper from '../../../components/FIconWrapper'
import EFormInvalidInput from '../../../components/EFormInvalidInput'
import FModal from '../../../components/FModal'

const CustomerAdd = ({ isModalOpen, setIsModalOpen, callBackFunction }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { response, loading, error, totalNumberOfPages, Refetch } =
    useCustomAxios({
      method: 'GET',
      url: `/product/listAllProducts?page=0&size=20`,
    })
  const ValidationSchema = Yup.object().shape({
    customerName: Yup.string().required('الاسم مطلوب'),
    customerPhone: Yup.string().required('رقم الهاتف مطلوب'),
  })
  const handleRequestBody = values => {
    let requestBody = {
      customerName: values.customerName,
      customerPhoneNumber: values.customerPhone,
    }
    return requestBody
  }
  const handleFormSubmission = values => {
    setIsLoading(true)
    axiosInstance
      .post('/customer', handleRequestBody(values))
      .then(() => {
        setIsLoading(false)
        toast.success('تم إضافة العميل بنجاح')
        setIsModalOpen(false)
        callBackFunction()
      })
      .catch(err => {
        setIsLoading(false)
        toast.error(err.response.data.message)
      })
  }
  if (loading) {
    return <div>loading...</div>
  } else {
    return (
      <>
        <FModal
          title={'اضافة عميل'}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
        >
          <Formik
            validationSchema={ValidationSchema}
            enableReinitialize={true}
            initialValues={{
              customerName: '',
              customerPhone: '',
            }}
            onSubmit={handleFormSubmission}
          >
            {({
              handleSubmit,
              handleBlur,
              values,
              errors,
              touched,
              handleChange,
            }) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                className={'w-full rounded-md'}
              >
                <SFormWrapper>
                  <FLabel htmlFor={'customerName'} className={'w-full'}>
                    الاسم
                  </FLabel>
                  <FInputField
                    name={'customerName'}
                    id={'customerName'}
                    type={'text'}
                    placeholder={`أدخل الاسم`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.customerName}
                  />
                  <EFormInvalidInput
                    errors={errors}
                    touched={touched}
                    FieldName={'customerName'}
                  />
                </SFormWrapper>
                <SFormWrapper>
                  <FLabel htmlFor={'customerPhone'} className={'w-full'}>
                    رقم الهاتف
                  </FLabel>
                  <FInputField
                    name={'customerPhone'}
                    id={'customerPhone'}
                    type={'text'}
                    placeholder={`أدخل رقم الهاتف`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.customerPhone}
                  />
                  <EFormInvalidInput
                    errors={errors}
                    touched={touched}
                    FieldName={'customerPhone'}
                  />
                </SFormWrapper>
                <div>
                  <FButton type={'submit'}>
                    <FIconWrapper>
                      <span>أضافة</span>
                      <ESpinner isVisible={isLoading} />
                    </FIconWrapper>
                  </FButton>
                </div>
              </form>
            )}
          </Formik>
        </FModal>
      </>
    )
  }
}
export default CustomerAdd
