import FModal from '../../../components/FModal'
import { useFormik } from 'formik'
import FButton from '../../../components/FButton'
import React from 'react'
import { getProductById } from '../../../helpers/utils'
import FLabel from '../../../components/FLabel'
import { useCustomAxios } from '../../../Hooks/useAxios'
import { produce } from 'immer'
import { axiosInstance } from '../../../api/requister'

const UpgradeSubscriptionModal = ({
  isOpen,
  setIsOpen,
  subscriptionObject,
}) => {
  const residuals = useCustomAxios({
    method: 'GET',
    url: `/ResidualFees/findAllResidualFees?customerPhoneNumber=${subscriptionObject?.customerSubscription?.customer?.phoneNumber}&page=0&size=100`,
  })

  const updateInstallments = (formik, installmentId, checked) => {
    formik.setValues(prevState =>
      produce(prevState, draftState => {
        const selectedInstallment = draftState.installments.find(
          item => parseInt(item.id) === parseInt(installmentId),
        )
        if (selectedInstallment) {
          selectedInstallment.checked = checked
        } else {
          selectedInstallment.checked = false
        }
      }),
    )
  }

  const updateResiduals = (residualId, checked) => {
    Formik.setValues(prevState =>
      produce(prevState, draftState => {
        const selectedResidual = draftState.residuals.find(
          item => item.id === residualId,
        )
        if (selectedResidual) {
          selectedResidual.checked = checked
        }
      }),
    )
  }

  const Formik = useFormik({
    initialValues: {
      name: '', // Add a valid name for the form
      installments:
        getProductById(
          subscriptionObject?.customerSubscription?.productId,
        )?.productInstallments?.map(installment => ({
          ...installment,
          checked: false,
        })) ?? [],
      residuals:
        residuals?.response?.residualsFees?.map(residual => ({
          ...residual,
          checked: false,
        })) ?? [],
    },
    onSubmit: values => {
      console.log(values)
      axiosInstance
        .post(
          `/customersubscription/${subscriptionObject.customerSubscription.id}/upgrade`,
          {
            installmentId: values.installments
              .filter(item => item.checked)
              .map(item => item.id)[0],
            residualIds: values.residuals
              .filter(item => item.checked)
              .map(item => item.id),
          },
        )
        .then(() => {
          toast.success('تم ترقية الاشتراك بنجاح')

          setIsOpen(false)
        })
        .catch(err => {
          toast.error(err.response.data.message)
        })
    },
    enableReinitialize: true,
  })

  if (!subscriptionObject) {
    return <div>Loading...</div> // Render a loading state when subscriptionObject is not available
  }

  return (
    <>
      <FModal
        title={'Upgrade Subscription'}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <div className={'flex flex-col gap-1'}>
          <span>الباقات المتاحة للترقية</span>
          {Formik.values.installments.map(installment => (
            <div className={'flex gap-1'} key={installment.id}>
              {installment.id}
              <input
                type={'radio'}
                id={installment.id}
                name='installment' // Add a name to group radio inputs
                checked={installment.checked}
                onChange={e => {
                  updateInstallments(
                    Formik,
                    parseInt(installment.id),
                    e.target.checked,
                  )
                }}
              />
              <FLabel htmlFor={installment.id}>
                {`${installment.installmentDesc} بقيمة ${installment.installmentCost} جنيه `}
              </FLabel>
            </div>
          ))}
          <span>العمليات المالية المعلقة</span>
          {Formik.values.residuals.map(residual => (
            <div className={'flex gap-1'} key={residual.id}>
              <input
                type={'checkbox'}
                id={residual.id}
                checked={residual.checked}
                onChange={e => {
                  updateResiduals(residual.id, e.target.checked)
                }}
              />
              <FLabel htmlFor={residual.id}>{residual.amount}</FLabel>
            </div>
          ))}

          <div className={'flex items-center gap-2'}>
            <FButton type={'submit'} onClick={Formik.handleSubmit}>
              Upgrade
            </FButton>
            <FButton onClick={() => setIsOpen(false)}>Cancel</FButton>
          </div>
        </div>
      </FModal>
    </>
  )
}

export default UpgradeSubscriptionModal
