import FButton from '../../../components/FButton'
import React, { useState } from 'react'
import { axiosInstance } from '../../../api/requister'
import { toast } from 'react-toastify'
import { getProductById, localDateToISO } from '../../../helpers/utils'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import FRow from '../../../components/FRow'
import { useLocalStorage } from 'usehooks-ts'
import FProductTag from '../../../components/FProductTag'
import FCol from '../../../components/FCol'
import ECard from '../../../components/ECard'
import { LineChart, Phone } from 'lucide-react'
import FIconWrapper from '../../../components/FIconWrapper'
import RemoveAssignationModal from './RemoveAssignationModal'
import CallReport from '../Calls/CallReport'
import CallReportBeta from '../Calls/CallReportBeta'
import DistributionModal from './DistributionModal'

const Distribution = () => {
  const [isDistributionModalOpen, setIsDistributionModalOpen] = useState(false)
  const [users] = useLocalStorage('users', [])
  const reasonMap = {
    MISSING_DATA: 'بيانات ناقصة',
    SUSPICIOUS: 'مشبوه',
    RENEWAL: 'تجديد',
  }

  const handleSingleAgentAssignation = (callObject, user) => {
    axiosInstance
      .post(`call/${callObject.id}`, {
        callId: callObject.id,
        callStatus: 'ASSIGNED',
        userEmail: user.email,
      })
      .then(res => {
        toast.success('تم تعيين المكالمة بنجاح')
        Refetch()
      })
      .catch(err => {
        toast.error('حدث خطأ ما')
      })
  }
  const cols = [
    {
      Header: 'معرف',
      accessor: 'id',
    },
    {
      Header: '#issues',
      accessor: 'numberOfCallIssues',
    },

    {
      Header: 'المكالمات',
      accessor: 'callType',
      cell: (value, row) => {
        return (
          <span
            className={
              'flex w-fit items-center gap-2 rounded-full bg-orient-500 py-0.5 px-2 text-xs font-semibold text-white'
            }
          >
            {reasonMap[value] ?? ''}
          </span>
        )
      },
    },
    {
      Header: 'توقيت المكالمة',
      accessor: 'callDate',
      cell: (value, row) => {
        return (
          moment(new Date(row.callDate)).format('DD/MM/YYYY') +
          '  ' +
          moment(new Date(row.callDate)).format('h:mm A')
        )
      },
    },
    {
      Header: 'المنتجات المتعلقة بالمكالمة',
      accessor: 'callIssues',
      cell: (value, row) => {
        return value.map(issue => {
          return (
            <FProductTag
              productName={getProductById(issue?.productId)?.name ?? ''}
            />
          )
        })
      },
    },

    {
      Header: 'العميل',
      accessor: 'customer',
      cell: (value, row) => {
        return (
          <Link
            to={'/customers/details/' + value.phoneNumber}
            className={
              'flex flex-col rounded-xl border bg-orient-100 px-2 text-orient-600'
            }
          >
            <span>{value?.name ?? ''}</span>
            <span> {value?.phoneNumber ?? ''}</span>
          </Link>
        )
      },
    },
    {
      Header: 'الموظف',
      accessor: 'user',
      cell: (value, row) => {
        return (
          <>
            <select
              onChange={e => {
                handleSingleAgentAssignation(
                  row,
                  users.find(user => user.email === e.target.value),
                )
              }}
              value={value.email}
            >
              <option value={''}>select agent</option>
              {users?.map(agent => (
                <option value={agent.email}>{agent.name}</option>
              ))}
            </select>
          </>
        )
      },
    },
  ]

  const [selectedTab, setSelectedTab] = useState(0)

  const notAssignedCalls = useQuery({
    queryKey: ['notAssignedCalls'],
    queryFn: () =>
      axiosInstance
        .get(
          `call/listCountCalls?callStatus=NEW&endDateCompareOperator=%3D&extraQueryParams=c.creationDate%20%3D'2024-10-14'`,
        )
        .then(res => res.data),
  })

  const notAssignedCallsAggregates = useQuery({
    queryKey: ['notAssignedCallsAggregates'],
    queryFn: () =>
      axiosInstance
        .get(`call/listCountCalls?callStatus=NEW&endDateCompareOperator=%3D`)
        .then(res => res.data),
  })

  const AssignedCalls = useQuery({
    queryKey: ['AssignedCalls'],
    queryFn: () =>
      axiosInstance
        .get(
          `call/?page=0&size=200&callStatus=ASSIGNED&endDate=${localDateToISO(
            new Date(),
          )}`,
        )
        .then(res => res.data),
  })

  const AssignedCallsNotStatic = useQuery({
    queryKey: ['AssignedCallsNotStatic'],
    queryFn: () =>
      axiosInstance
        .get(
          `call/?page=0&size=200&callStatus=ASSIGNED&callStatus=DONE&callStatus=OUT_OF_TRIALS&endDate=${localDateToISO(
            new Date(),
          )}`,
        )
        .then(res => res.data),
  })

  const [lastSync, setLastSync] = useState(null)

  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false)
  return (
    <>
      <div className={'flex w-full items-center justify-between border-b p-3'}>
        <span className={'text-md block  font-semibold'}>توزيع المكالمات</span>
        <div className='flex items-center gap-2'>
          <CallReport />
          <CallReportBeta />
          <FButton
            onClick={() => {
              setIsDistributionModalOpen(true)
            }}
            btnType={'secondary'}
          >
            <span className={'whitespace-nowrap'}>توزيع متساوي</span>
          </FButton>

          <RemoveAssignationModal />
        </div>
      </div>
      <div
        className={
          'sticky top-0 flex items-center justify-between border   border-b bg-gray-50 px-2 pt-2'
        }
      >
        <FRow>
          <span
            onClick={() => {
              setSelectedTab(0)
            }}
            className={`block cursor-pointer  bg-gray-50 p-2 text-gray-400  ${
              selectedTab === 0 &&
              'border-b-2 border-blue-700 bg-gray-400 text-gray-800'
            }`}
          >
            <FIconWrapper>
              <LineChart size={20} />
              الاحصائيات
            </FIconWrapper>
          </span>
        </FRow>
      </div>

      <div>
        <DistributionModal
          isOpen={isDistributionModalOpen}
          setIsOpen={setIsDistributionModalOpen}
        />

        <ECard className={'mt-2'}>
          <ECard.Header>
            <span className={'font-medium'}>
              مكالمات متعلقة باليوم الحالي فقط بتاريخ{' '}
              {moment().format('DD/MM/YYYY')}
            </span>
          </ECard.Header>
          <ECard.Body>
            <div
              className={'m-2 rounded-lg border border-gray-300 p-3 shadow-sm'}
            >
              <FRow>
                <FCol>
                  <span>مكالمات تم انشاءها اليوم و لم يتم تعين موظفين لها</span>
                </FCol>
              </FRow>
              <div
                className={`mt-3 grid grid-cols-${notAssignedCalls?.data?.callsCount?.length} gap-3`}
              >
                {notAssignedCalls?.data?.callsCount?.map(call => {
                  return (
                    <div
                      className={
                        'flex flex-col items-center justify-center rounded-md border  border-gray-300 bg-gray-50 px-3  py-0.5 shadow-sm dark:bg-gray-900'
                      }
                    >
                      <span className={'text-sm'}>{call?.productName}</span>
                      <span className={'text-xl font-medium  '}>
                        {call?.count}
                      </span>
                    </div>
                  )
                })}
              </div>
            </div>
            <div
              className={'m-2 rounded-lg border border-gray-300 p-3 shadow-sm'}
            >
              <FRow>
                <FCol>
                  <span>
                    مكالمات اليوم التي تم تعين موظفين لها و تزال مفتوحة (بعض
                    المكالمات يتم تعينها بتاريخ اليوم بشكل تلقائي)
                  </span>
                </FCol>
              </FRow>
              <div
                className={`mt-3 grid grid-cols-${AssignedCalls?.data?.callsCount?.length} gap-3`}
              >
                {AssignedCalls?.data?.callsCount?.map(call => {
                  return (
                    <div
                      className={
                        'flex flex-col items-center justify-center rounded-md border  border-gray-300 bg-gray-50 px-3  py-0.5 shadow-sm dark:bg-gray-900'
                      }
                    >
                      <span className={'text-sm'}>{call?.productName}</span>
                      <span className={'text-xl font-medium'}>
                        {call?.count}
                      </span>
                    </div>
                  )
                })}
              </div>
            </div>

            <div
              className={'m-2 rounded-lg border border-gray-300 p-3 shadow-sm'}
            >
              <FRow>
                <FCol>
                  <span>
                    مكالمات اليوم التي تم تعين موظفين لها (بعض المكالمات يتم
                    تعينها بتاريخ اليوم بشكل تلقائي)
                  </span>
                </FCol>
              </FRow>
              <div
                className={`mt-3 grid grid-cols-${AssignedCallsNotStatic?.data?.callsCount?.length} gap-3`}
              >
                {AssignedCallsNotStatic?.data?.callsCount?.map(call => {
                  return (
                    <div
                      className={
                        'flex flex-col items-center justify-center rounded-md border  border-gray-300 bg-gray-50 px-3  py-0.5 shadow-sm dark:bg-gray-900'
                      }
                    >
                      <span className={'text-sm'}>{call?.productName}</span>
                      <span className={'text-xl font-medium'}>
                        {call?.count}
                      </span>
                    </div>
                  )
                })}
              </div>
            </div>
          </ECard.Body>
        </ECard>
      </div>
    </>
  )
}
export default Distribution
