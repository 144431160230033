import { Link } from 'react-router-dom'
import { HiOutlinePhone } from 'react-icons/hi'
import React from 'react'

const MAP = {
  RESCHEDULE: 'مؤجلة',
  CANCELED: 'ملغية',
  DONE: 'منتهية',
  MISSING_DATA_DONE: 'منتهية',
  UNREACHABLE: 'لا يمكن الوصول للعميل',
  SOLD: 'مباعة',
}

const CustomerRecord = ({
  name,
  phone,
  product,
  bulletColor,
  className,
  scheduleInstance,
  setCustomer,
  setScheduleInstance,
  setIsCustomerModalOpen,
  type,
  setCallType,
}) => (
  <div
    className={`grid grid-cols-${
      type === 'active' ? '10' : '9'
    } items-center justify-center gap-5 gap-x-3 bg-white py-1 px-1 shadow-sm dark:bg-gray-900 ${className}`}
  >
    <div className={'col-span-2 flex  items-center gap-2 '}>
      <div
        className={bulletColor}
        style={{ width: '5px', height: '5px', borderRadius: '0px' }}
      />
      <Link className={`flex flex-col `} to={`/customers/details/${phone}`}>
        <span className={'text text-right' + ' text-orient-500 underline'}>
          {name ? name : 'N/A'}
        </span>
      </Link>
    </div>
    <div>{scheduleInstance?.callIssues?.length || 0}</div>

    <span className={'text-xs'}>{phone}</span>
    <span className={'text-sm  text-gray-500 dark:text-gray-100  '}>
      {scheduleInstance?.callType}
    </span>
    <span className={'text-sm  text-gray-500 dark:text-gray-100  '}>
      ( {scheduleInstance?.numberOfCalls + 1} مكالمة )
    </span>
    <span className={'text-sm  text-gray-500 dark:text-gray-100  '}>
      {scheduleInstance?.reason}
    </span>
    <span className={'text-sm  text-gray-500 dark:text-gray-100  '}>
      {scheduleInstance?.callAgentInteractionType
        ? MAP[scheduleInstance?.callAgentInteractionType]
        : 'لم يتم التواصل'}
    </span>
    <div className={'flex flex-row-reverse'}>
      <button
        className={
          'rounded border border-gray-300 bg-orient-500 p-2 text-gray-100 shadow-sm hover:border-orient-700 hover:bg-orient-500 hover:text-white'
        }
        onClick={() => {
          setCustomer(scheduleInstance.customer)
          setScheduleInstance(scheduleInstance)
          setCallType(type)
          setIsCustomerModalOpen(true)
        }}
      >
        <HiOutlinePhone />
      </button>
    </div>
  </div>
)
export default CustomerRecord
